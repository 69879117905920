// General stylesheets are imported here.
// Beware of all SASS code that generates CSS output only ONCE nad here.
import "../muklit/styles/index.scss";
import "./styles/index.scss";

import { Application } from "hiyo/application";
import { InvipoMenu } from "./components/common/invipo-menu/invipo-menu";
import { InvipoContext } from "./context/invipo-context";
import { LoginPage } from "./components/common/login-page/login-page";
import { Log, LogColor } from "../hiyo/log";
import { Helpers } from "../hiyo/helpers";
import { Monitoring } from "./views/monitoring";
import { ElementTooltip } from "./components/common/element-tooltip/element-tooltip";
import { Partials } from "./partials/partials";
import { ToastManager } from "./components/common/toast-manager/toast-manager";
import { Language } from "../hiyo/locale-manager";
import { View } from "../hiyo/view";
import { Home } from "./views/home";
import { Profile } from "./views/profile";
import { Settings } from "./views/settings";
import { Wall } from "./views/wall";
import { ToastAction } from "../muklit/components/notification-toast/types";
import { Data } from "./views/data";
import { City } from "./views/city";
import { Management } from "./views/management";
import { Insights } from "./views/insights";
import { Items } from "./views/items";
import { Dashboard } from "./views/dashboard";
import { Extravilan } from "./views/extravilan";
import { ImageTooltip } from "./components/common/image-tooltip/image-tooltip";

//Log.i(" @(  .@/%@  #@        @&*@, @@*         @@# &@  #@@@@@@@@*     @@@@@@@@.  ");
//Log.i("  /@..@/%@  #@.@/     @&*@, ,@@@       #@&  &@  #@      /@@  #@@      (@@ ");
//Log.i("    @&  %@  #@.@/%@   @&*@,   #@&     /@@   &@  #@       %@ *@@        &@%");
//Log.i(" ,@* .@/%@  #@.@/%@,@*@&*@,    @@#   /@@.   &@  #@,,,,,/@@& *@@        &@%");
//Log.i("   #@.@/%@  #@.@/  ,@*@&*@,     @@/ ,@&,    &@  #@%%%%%(    *@@        &@%");
//Log.i(" ,@* .@/%@  #@.@/     @&*@,     .@@@@@*     &@  #@          ,&@@      (@@/");
//Log.i("@% #@.@/%@  #@.@/       *@,      ,&@@/      &@  #@            /@@@@@@@@#  ");

// Requires
let Handlebars = require("handlebars/dist/handlebars");

export class InvipoApplication extends Application<InvipoContext> {

    // Components
    public login: LoginPage;
    public menu: InvipoMenu;
    public toasts: ToastManager;

    public constructor(context: InvipoContext) {
        super(context);
    }

    public onCreate(): void {
        // Register partials
        Partials.registerAll();

        // Create components
        this.createLogin();
        this.createMenu();
        this.createManager();

        // Register components
        this.registerComponent(this.menu);
        this.registerComponent(this.toasts);
    }

    public onViewChange(view: View) {
        // Set body class for cross-application styling
        document.body.className = `project-${this.context.options.project} view-${Helpers.toKebabCase(view.id)}`

        // Menu must be redrawn
        this.menu.update();
    }

    private createLogin(): void {
        // Create component
        this.login = new LoginPage(this.context);

        // User successfully logged
        this.login.onUserLogin = async () => {
            // Fadeout animation
            document.body.style.opacity = "0";

            // Wait for animation
            await Helpers.sleep(300);

            // Detach login page
            this.login.detach();

            // Run the application
            await this.run();
        }
    }

    private createMenu(): void {
        // Invipo menu
        this.menu = new InvipoMenu(this.context, {});

        // Change application view
        this.menu.onViewSelect = (id: string) => {
            this.context.application.route({ viewId: id });
        }

        // Logout from application
        this.menu.onUserLogout = async () => {
            // Fadeout animation
            document.body.style.opacity = "0";

            // Wait for animation
            await Helpers.sleep(300);

            // Delete user token
            await this.context.invipo.logoutUser();

            // We need to remove user principal from context
            this.context.setUser(null);

            // After refresh with no user in context login page will be displayed again
            document.location.reload();
        }

        // Change application language
        this.menu.onLanguageSelect = async (langId: Language) => {
            // Switch language in context
            this.context.locale.setLangId(langId);

            // Reload application with new language to set
            window.location.reload();
        }
    }

    private createManager(): void {
        // Create component
        this.toasts = new ToastManager(this.context);
    }

    public registerHelpers(): void {
        // Register general application helpers
        super.registerHelpers();

        // Classification category
        Handlebars.registerHelper("category", (id: string) => {
            if (!this.context.config.categories) {
                return null;
            }
            return this.context.config.categories.find(x => x.id == id)?.name;
        });
    }

    public async preauthorize(): Promise<void> {
        Log.i(`InvipoApplication: Performing authorization check before running`);

        if (this.context.user) {
            // User already in context, we will validate his token
            this.context.invipo.enableBasicAuthorization(this.context.user.token);
            this.context.invipo.enableUserUuid(this.context.user.uuid);

            try {
                // Validate token via request to logged user
                await this.context.invipo.getLoggedUser();

                Log.i(`InvipoApplication: User "${this.context.user.name}" is valid (token has not expired)`);
            }
            catch (e) {
                Log.i(`InvipoApplication: User "${this.context.user.name}" logged out (token has expired)`);

                // Attach login page and exit
                this.login.attach();

                // Must quit
                return;
            }

            // User authorized, we can run the application
            await this.run();
        }
        else {
            // No user in context
            Log.i(`InvipoApplication: User principal not found (from localStorage)`);

            // Attach login page
            this.login.attach();
        }
    }

    public watchDom(): void {
        // Watch DOM changes via MutationObserver and utilize tooltips
        const observer = new MutationObserver((list: MutationRecord[]) => {
            let count = 0;

            // All records in change list
            for (let record of list) {

                // New nodes were added?
                if (record.addedNodes.length > 0) {

                    // Find all elements with tooltip
                    let tooltips = document.body.querySelectorAll<HTMLElement>("[data-tooltip]");

                    // Create tooltips
                    tooltips.forEach((element: HTMLElement) => {
                        // New tooltip created returns true
                        if (ElementTooltip.create(this.context, element)) {
                            // Counter
                            count += 1;
                        }
                    });

                    // Find all elements with tooltip
                    let images = document.body.querySelectorAll<HTMLElement>("[data-image]");

                    // Create tooltips
                    images.forEach((element: HTMLElement) => {
                        // New tooltip created returns true
                        if (ImageTooltip.create(this.context, element)) {
                            // Counter
                            count += 1;
                        }
                    });

                    // Find all elements with copy to clipboard function
                    let clipboard = document.body.querySelectorAll<HTMLElement>("[data-clipboard]");

                    // Copy element text to clipboard on click
                    clipboard.forEach((element: HTMLElement) => {
                        // Copy on click to clipboard
                        if (!element.onclick) {
                            element.onclick = async (e: MouseEvent) => {
                                // Copy to clipboard and show toast
                                await navigator.clipboard.writeText(element.innerText);
                                this.toasts.showInfoToast(null, "common.textCopied");
                            }
                        }
                    });
                }
            }

            if (count > 0) {
                Log.d(`${count} tooltip element(s) created and assigned`);
            }
        });

        // Start to observe
        observer.observe(document, {
            attributes: false,
            childList: true,
            subtree: true
        });
    }

    public checkVersion(): void {
        // Get current version from bundle
        Helpers.loadJson(`./version.json?t=${new Date().getTime()}`)
            .then((data: any) => {
                // Compare to current version
                if (data.version != this.context.options.version) {
                    // New version available
                    Log.i(`Frontend: New version ${data.version} is available`);

                    // Show toast
                    let toast = this.toasts.showLinkToast(`Version ${this.context.options.version} available`, "A newer version of Invipo application is available", null, "labels.reload");

                    // Reload callback
                    toast.onAction = (a: ToastAction) => {
                        // Not supported officialy but the only way how to do hard refresh (no caching)
                        (<any>location.reload)(true);
                    }
                }
            })
            .catch(() => {
                Log.w("Missing version.json, new version checking is not be available");
            });
    }

    public bindTranslator(): void {
        // Add global click listener to invoke translation dialog
        document.addEventListener("click", (e: MouseEvent) => {
            // User must press CTRL and ALT key
            if (!e.altKey || !e.ctrlKey) {
                return;
            }

            // Get node text
            let text = (<HTMLElement>e.target)?.innerText;

            // Get component path
            let path = [];

            // Browse DOM tree from element to root
            for (let p of e.composedPath().reverse()) {
                // Get component name
                let component = (<HTMLElement>p)?.attributes?.getNamedItem("component")?.value;

                // First element in tree that is component
                if (component) {
                    path.push(component);
                }
            }

            // In future this will invoke a translation dialog to send a request to backend system
            Log.i(`Translation: ${path.join("/")} to proper translate "${text}"`, LogColor.Navy);

            // Stop propagation to other listeners
            e.stopImmediatePropagation();
        })
    }

    private handleErrors(): void {
        // Unauthorized means relogin
        this.context.invipo.http.onResponse = (response: Response) => {
            switch (response.status) {
                case 401:
                case 403:
                    window.location.reload();
                    break;
            }
        }

        // Network error will cause toast display
        this.context.invipo.http.onError = (response: Response) => {
            this.toasts.showErrorToast("common.connectionError", this.context.locale.getMessage("common.apiFailed", response.status || ""));
        }

        // Information about slow network
        this.context.invipo.http.onTimeout = (timeout) => {
            this.toasts.showErrorToast("common.slowNetwork", "common.apiTimeout");
        }
    }

    private async loadConfig(): Promise<void> {
        // Load config with specific keys
        this.context.config = await this.context.invipo.getConfig();

        // Set defaults
        this.context.config.categories = this.context.config.categories || [];

        // Sort classification as wee need to respect order values
        this.context.config.categories.sort((a: any, b: any) => {
            return a.order - b.order;
        });

        Log.i(`Context: Server config loaded (keys=${Object.keys(this.context.config)})`);
    }

    private async loadLicense(): Promise<void> {
        // Load server license
        try {
            this.context.license = await this.context.invipo.getLicense();
            Log.i(`Context: Server license loaded (${this.context.license.project} for ${this.context.license.issuedTo})`);
        }
        catch (e) {
            Log.w(`Context: License not found, running in EVALUATION mode`);
        }
    }

    private async loadInfo(): Promise<void> {
        // Load server info
        try {
            this.context.info = await this.context.invipo.getInfo();
            Log.i(`Context: Server info loaded (version=${this.context.info.version})`);
        }
        catch (e) {
            Log.w(`Context: Info not found`);
        }
    }

    private async loadData(): Promise<void> {
        // Synchronize data store
        await this.context.data.sync();
    }

    private registerViews(): void {
        // First, clear all views
        this.views = [];

        // Home is default hidden view
        this.registerView(new Home(this.context));

        // We do not have store for views, so we must add instances manually
        if (this.context.options.dashboard) {
            this.registerView(new Dashboard(this.context));
        }

        if (this.context.options.wall) {
            this.registerView(new Wall(this.context));
        }

        /*if (this.context.options.views.includes("Desk")) {
            this.registerView(new Desk(this.context));
        }*/

        if (this.context.options.city) {
            // Is extravilan?
            if (this.context.options.city?.Extravilan) {
                this.registerView(new Extravilan(this.context));
            }
            // Major city view
            else {
                this.registerView(new City(this.context));
            }
        }

        if (this.context.options.extravilan) {
            this.registerView(new Extravilan(this.context));
        }

        if (this.context.options.insights) {
            this.registerView(new Insights(this.context));
        }

        if (this.context.options.data) {
            this.registerView(new Data(this.context));
        }

        /*if (this.context.options.views.includes("Schemas")) {
            this.registerView(new Schemas(this.context));
        }*/

        if (this.context.user.boss || this.context.user.permissions?.includes("Administration")) {
            this.registerView(new Items(this.context));
        }

        this.registerView(new Monitoring(this.context));

        if (this.context.options.management && (this.context.user.boss || this.context.user.permissions?.includes("Administration"))) {
            this.registerView(new Management(this.context));
        }

        this.registerView(new Profile(this.context));

        // Settings view only for administrators
        if (this.context.user.boss || this.context.user.permissions?.includes("Administration")) {
            this.registerView(new Settings(this.context));
        }
    }

    public async run(): Promise<void> {
        // Load server config and merge it to the context options
        await this.loadConfig();

        // Load server license info
        await this.loadLicense();

        // Load server version info
        await this.loadInfo();

        // Synchronize data store
        await this.loadData();

        // Set new document title
        document.title = this.context.options.name;

        // Handle HTTP errors globally
        this.handleErrors();

        // Add views based on invipo.json and user role
        this.registerViews();

        // Default run
        super.run();

        // Watch for DOM changes
        this.watchDom();

        // Check for new version
        this.checkVersion();

        // Bind translator dialog to CTRL+ALT+click
        this.bindTranslator();

        // Fadein animation
        document.body.style.opacity = "1";
    }
}
