import "./traffic-incident-count-report.scss";
import * as template from "./traffic-incident-count-report.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { Panel } from "../../common/panel/panel";
import { TrafficIncidentCountReportOptions } from "./types";
import { Form } from "muklit/components/form/form";
import { ItemSelect } from "../../common/item-select/item-select";
import { PanelChart, PanelChartData, PanelKpis, PanelTable } from "../../common/panel/types";
import { Helpers } from "hiyo/helpers";
import { Select } from "muklit/components/select/select";
import { InvipoHelpers } from "../../../invipo-helpers";
import { MenuItem } from "muklit/components/overflow-menu/types";
import { RangeInput } from "muklit/components/range-input/range-input";

export class TrafficIncidentCountReport extends Panel<TrafficIncidentCountReportOptions> {

    // Properties
    public days: PanelChart;
    public kpis: PanelKpis;
    public types: PanelChart;
    public table: PanelTable;

    public constructor(context: InvipoContext, options?: TrafficIncidentCountReportOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createForm();
    }

    protected createForm(): void {
        // Default notification form
        this.form = new Form(this.context, {
            style: "Light",
            fieldsets: [
                {
                    name: "General",
                    fields: [
                        new RangeInput(this.context, {
                            style: "Light",
                            name: "interval",
                            type: "Range",
                            label: "forms.fields.date",
                            value: {
                                from: new Date(new Date().setHours(-24 * ((new Date().getDay() + 6) % 7), 0, 0, 0) - 604800000).toISOString(),
                                to: new Date(new Date().setHours(-24 * ((new Date().getDay() + 6) % 7), 0, 0, 0) - 1).toISOString(),
                                range: "LastWeek"
                            },
                            placeholderText: "forms.placeholders.anytime",
                            width: 320,
                            bright: true,
                            required: true
                        }),
                        new ItemSelect(this.context, {
                            style: "Light",
                            name: "itemId",
                            label: "forms.fields.item",
                            value: this.options.itemId,
                            placeholderText: "forms.placeholders.all",
                            distinct: "TrafficIncidentsData",
                            items: [],
                            width: 320,
                            bright: true
                        }),
                        new Select(this.context, {
                            style: "Light",
                            name: "type",
                            label: "forms.fields.type",
                            placeholderText: "forms.placeholders.all",
                            items: InvipoHelpers.toMenuItems(this.context.data.getDistinct("TrafficIncidents", "type"), "enums.TrafficIncidentType."),
                            width: 320,
                            bright: true
                        })
                    ]
                }
            ]
        });

        // Register component
        this.registerComponent(this.form, "form");
    }

    public async extraLoad(): Promise<void> {
        // Get simplified form data
        let form = this.form.getData(true);

        // Assign form data to panel search options
        this.options.search = this.form.getData();

        // Query string
        let query = "";

        if (form.itemId) {
            query += `&item.id=${form.itemId}`;
        }

        if (form.type) {
            query += `&type=${form.type}`;
        }

        // Interval
        let from = new Date(form.from);
        let to = new Date(new Date(form.to).setHours(24, 0, 0, 0));

        // Violation data per day
        let data = await this.context.invipo.getQuery("traffic-incidents-by-day", `${query}&from=${from.toISOString()}&to=${to.toISOString()}`);

        // Calculate data extrems
        let total = data.map(x => x.count).reduce((r, n) => { return r + n}, 0);
        let highest = Math.max(...data.map(x => x.count));
        let lowest = Math.min(...data.map(x => x.count));

        // Build hours chart
        this.days = {
            type: "Bar",
            size: "Tall",
            name: "Speed",
            label: "components.TrafficIncidentCountReport.days",
            series: []
        }

        // Itterate over days as some data may miss
        from = new Date(form.from);
        do {
            // find data
            let d = data.find(x => new Date(x.timestamp).getTime() == from.getTime());

            // Has data?
            if (d) {
                this.days.series.push(
                    [
                        {
                            timestamp: from.toISOString(),
                            valueY: Helpers.toNumber(d.count),
                            valueX: Helpers.toShortDateString(d.timestamp),
                            percent: Helpers.range(0, 100, 0, highest * 1.2, d.count), // autoscale based on highest value
                            label: `${Helpers.toNumber(d.count)} ${this.context.locale.getMessage("units.incidents")}`,
                            color: InvipoHelpers.toChartColor(1)
                        }
                    ]
                );
            }
            // No data
            else {
                this.days.series.push(
                    [
                        {
                            timestamp: from.toISOString(),
                            valueX: Helpers.toShortDateString(from)
                        }
                    ]
                );
            }

            // Add one day
            from.setHours(24, 0, 0, 0);
        }
        while (from.getTime() < to.getTime())

        // Build speed KPIs
        this.kpis = {
            size: "Third",
            data: [
                {
                    label: "components.TrafficIncidentCountReport.totalCount",
                    value: Number.isInteger(total) ? `${Helpers.toNumber(total)} ${this.context.locale.getMessage(`units.incidents`)}` : this.context.locale.getMessage("common.noData"),
                    description: `${Helpers.toNumber(data.length)} ${this.context.locale.getMessage("units.days")}`
                },
                {
                    label: "components.TrafficIncidentCountReport.highestCount",
                    value: Number.isInteger(highest) ? `${Helpers.toNumber(highest)} ${this.context.locale.getMessage(`units.incidents`)}` : this.context.locale.getMessage("common.noData"),
                    description: Number.isInteger(highest) ? Helpers.toDateString(data.find(x => x.count == highest)?.timestamp) : ""
                },
                {
                    label: "components.TrafficIncidentCountReport.lowestCount",
                    value: Number.isInteger(lowest) ? `${Helpers.toNumber(lowest)} ${this.context.locale.getMessage(`units.incidents`)}` : this.context.locale.getMessage("common.noData"),
                    description: Number.isInteger(lowest) ? Helpers.toDateString(data.find(x => x.count == lowest)?.timestamp) : ""
                }
            ]
        }

        // Build categories chart
        this.types = {
            type: "Bar",
            size: "Medium",
            name: "Type",
            series: [],
            legend: []
        }

        // Itterate over days as some data may miss
        from = new Date(form.from);
        do {
            // find data
            let d = data.find(x => new Date(x.timestamp).getTime() == from.getTime());

            // Has data?
            if (d) {
                // Define series
                let series: PanelChartData[] = [];

                // Color index (first is used for main chart)
                let color = 2;

                // Iterate violation types
                for (let t of this.context.options.enums.TrafficIncidentType) {
                    // Find type data
                    let count = (<any[]>d.types).find(x => x.type == t)?.count;

                    // Push series data
                    series.push({
                        timestamp: from.toISOString(),
                        valueX: Helpers.toShortDateString(from.toISOString()),
                        percent: count / d.count * 100,
                        label: `${this.context.locale.getMessage(`enums.TrafficIncidentType.${t}`)}<br />${Helpers.toNumber(d.count)} ${this.context.locale.getMessage("units.incidents")}`,
                        color: InvipoHelpers.toChartColor(color++)
                    });
                }

                // Add all series
                this.types.series.push(series);
            }
            // No data
            else {
                this.types.series.push(
                    [
                        {
                            timestamp: from.toISOString(),
                            valueX: Helpers.toShortDateString(from)
                        }
                    ]
                );
            }

            // Add one day
            from.setHours(24, 0, 0, 0);
        }
        while (from.getTime() < to.getTime())

        // Build count table
        this.table = {
            name: "Table",
            label: "components.TrafficIncidentCountReport.table",
            columns: [
                {
                    style: "Label",
                    label: "tables.columns.date",
                    width: "99%"
                },
                {
                    label: "tables.columns.count",
                    style: "Bold",
                    align: "Right",
                    width: "80px"
                }
            ],
            rows: []
        };

        for (let d of data) {
            // Add table row
            this.table.rows.push({
                cells: [
                    Helpers.toDateString(d.timestamp),
                    Helpers.toNumber(d.count)
                ]
            });
        }
    }

}
